import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useUser } from "../views/user/hooks/useUser";
import Login from "../views/user/login/Login";
import PrivateRoute from "./privateRoute";
import Sidebar from "../components/Sidebar/Sidebar";
import Dashboard from "../views/dashboard/Dashboard";
import Locations from "../views/locations/Locations";
import Users from "../views/users/Users";
import Orders from "../views/orders/Orders";
import Products from "../views/products/products";
import ProductGroups from "../views/products/groups";
import Payments from "../views/payments/Payments";
import ProductJobs from "../views/jobs/ProductJobs";
import Reports from "../views/reports/Reports";
import Maintenance from "../views/maintenance/Maintenance";
import NotFound from "views/notFound/notFound";

function MyRoutes() {
  const { user } = useUser();
  const defaultProtectedRouteProps = {
    isAuthenticated: !!user,
    authenticationPath: "/login",
  };

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="/" element={<Navigate replace to="/dashboard" />} />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute
            {...defaultProtectedRouteProps}
            outlet={
              <Sidebar>
                <Dashboard />
              </Sidebar>
            }
          />
        }
      />
      <Route
        path={`/locations`}
        element={
          <PrivateRoute
            {...defaultProtectedRouteProps}
            outlet={
              <Sidebar>
                <Locations />
              </Sidebar>
            }
          />
        }
      />
      <Route
        path={"/users"}
        element={
          <PrivateRoute
            {...defaultProtectedRouteProps}
            outlet={
              <Sidebar>
                <Users />
              </Sidebar>
            }
          />
        }
      />
      <Route
        path={"/orders"}
        element={
          <PrivateRoute
            {...defaultProtectedRouteProps}
            outlet={
              <Sidebar>
                <Orders />
              </Sidebar>
            }
          />
        }
      />
      <Route
        path={"/products"}
        element={
          <PrivateRoute
            {...defaultProtectedRouteProps}
            outlet={
              <Sidebar>
                <Products />
              </Sidebar>
            }
          />
        }
      />
      <Route
        path={"/product-groups"}
        element={
          <PrivateRoute
            {...defaultProtectedRouteProps}
            outlet={
              <Sidebar>
                <ProductGroups />
              </Sidebar>
            }
          />
        }
      />
      <Route
        path={"/transactions"}
        element={
          <PrivateRoute
            {...defaultProtectedRouteProps}
            outlet={
              <Sidebar>
                <Payments />
              </Sidebar>
            }
          />
        }
      />
      <Route
        path={"/product-jobs"}
        element={
          <PrivateRoute
            {...defaultProtectedRouteProps}
            outlet={
              <Sidebar>
                <ProductJobs />
              </Sidebar>
            }
          />
        }
      />
      <Route
        path={"/reports"}
        element={
          <PrivateRoute
            {...defaultProtectedRouteProps}
            outlet={
              <Sidebar>
                <Reports />
              </Sidebar>
            }
          />
        }
      />
      <Route path={"/maintenance"} element={<Maintenance />} />
      <Route path={"/*"} element={<NotFound />} />
    </Routes>
  );
}

export default MyRoutes;
