import { useQuery } from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";

const fallback = null;

interface UseUsersProps {
  p?: number;
  c?: string;
  s?: string;
  l?: number;
  lm?: number;
}

export function useUsers({
  p = 1,
  c = "",
  s = "",
  l = 0,
  lm = 20,
}: UseUsersProps) {
  if (c === "") c = "name";
  const { user } = useUser();
  const { axiosInstance, getJWTHeader } = useAxios();

  async function getUsers() {
    if (!l) return null;
    const { data } = await axiosInstance({
      url: `/users?p=${p}&lm=${lm}&c=${c}&s=${s}&l=${l}`,
      method: "GET",
      headers: getJWTHeader(user),
    });
    return data;
  }

  const { data = fallback } = useQuery(
    [queryKeys.users, p, s, l, lm],
    () => getUsers(), {
    enabled: l !== 0
  }
  );

  return data;
}
