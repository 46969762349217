import { useQuery } from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";

const fallback = null;

interface UseProductsProps {
  p?: number;
  c?: string;
  s?: string;
  l?: number;
  lm?: number;
}

export function useProducts({
  p = 1,
  c = "",
  s = "",
  l = 0,
  lm = 20,
}: UseProductsProps) {
  if (c === "") c = "name";
  const { user } = useUser();
  const { axiosInstance, getJWTHeader } = useAxios();

  async function getProducts() {
    const { data } = await axiosInstance({
      url: `/v2/products?p=${p}&lm=${lm}&c=${c}&s=${s}&l=${l}`,
      method: "GET",
      headers: getJWTHeader(user),
    });

    return data;
  }

  const { data = fallback } = useQuery(
    [queryKeys.products, p, s, l, lm],
    () => getProducts(), {
    enabled: l !== 0
  }
  );

  return data;
}
