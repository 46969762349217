import { useQuery, useQueryClient } from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { clearStoredUser, getStoredUser } from "../../../user-storage";
import { queryKeys } from "../../../react-query/constants";
import { User } from "types";

export function useUser() {
  const { getJWTHeader, axiosInstance } = useAxios();

  async function getUser(user: User, signal: AbortSignal) {
    if (!user) {
      user = getStoredUser();
    }

    if (!user) return null;

    const { data } = await axiosInstance({
      url: `/users/${user.userId}`,
      method: "GET",
      signal,
      headers: getJWTHeader(user),
    });

    const updatedUser = {
      ...data?.data,
      id: user.userId,
      token: user.token,
    };

    return updatedUser;
  }

  const queryClient = useQueryClient();

  const { data: user } = useQuery(
    [queryKeys.user],
    ({ signal }) => {
      const cachedUser: any = queryClient.getQueryData([queryKeys.user]);

      if (cachedUser || cachedUser?.token) {
        return getUser(cachedUser, signal);
      } else {
        return null;
      }
    },
    {
      retry: 4,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  // meant to be called from useAuth
  function updateUser(newUser) {
    queryClient.setQueryData([queryKeys.user], newUser);
  }

  // meant to be called from useAuth
  function clearUser() {
    // reset user to null
    queryClient.setQueryData([queryKeys.user], null);

    // remove user appointments query
    queryClient.clear();
    clearStoredUser();
    queryClient.refetchQueries([queryKeys.user]);
  }

  return { user, updateUser, clearUser };
}
