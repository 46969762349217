import { useQuery } from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";

const fallback = null;

export function useSingleUser(userId: number) {
  const { user } = useUser();
  const { axiosInstance, getJWTHeader } = useAxios();

  async function getUser() {
    if (!userId) return null;
    const { data } = await axiosInstance({
      url: `/users/${userId}`,
      method: "GET",
      headers: getJWTHeader(user),
    });

    return data;
  }

  const { data = fallback } = useQuery([queryKeys.singleUser, userId], () =>
    getUser()
  );

  return data;
}
