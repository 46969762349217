import { useQuery } from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";

const fallback = null;

export function useOrder(orderId: number) {
  const { user } = useUser();
  const { axiosInstance, getJWTHeader } = useAxios();

  async function getOrder() {
    if (!orderId) return null;
    const { data } = await axiosInstance({
      url: `/v2/orders/${orderId}`,
      method: "GET",
      headers: getJWTHeader(user),
    });

    return data;
  }

  const { data = fallback } = useQuery([queryKeys.order, orderId], () =>
    getOrder()
  );

  return data;
}
