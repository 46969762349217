import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { Roles } from "../../helpers";
import { ROLES } from "../../helpers/constants";
import { useLocations } from "../locations/hooks/useLocations";
import { useUser } from "../user/hooks/useUser";
import { useAddUser } from "./hooks/useAddUser";
import { editUserStyles } from "./styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type FormValues = {
  name: string;
  email: string;
  location_id: number;
  role_id: number;
  enabled: boolean;
  notify: boolean;
};

const AddUser = ({ addModalOpen = false, setAddModalOpen }) => {
  const { user } = useUser();

  const mutateAddUser = useAddUser();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required!"),
    email: Yup.string().email("Incorrect email").required("Email required"),
    location_id: Yup.number().positive("Please select a Location"),
    role_id: Yup.number()
      .positive("Please select a Role")
      .typeError("Please select a Role"),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "all",
    defaultValues: {
      name: "",
      email: "",
      role_id: 0,
      location_id: user?.roleId === Roles.RoleManager ? user?.locationId : -1,
      enabled: true,
      notify: true,
    },
  });

  const onClose = () => {
    reset();
    setAddModalOpen(false);
  };

  const locations = useLocations({
    p: 1,
    lm: 50,
  });

  const onSubmit = (values: FormValues) => {
    const body = {
      ...values,
      merchant_id: 0,
    };
    mutateAddUser({ newUser: body });
    onClose();
  };

  useEffect(() => {
    if (locations?.data?.length >= 1) {
      if (localStorage.getItem("selectedLocation")) {
        const locationOption = JSON.parse(
          localStorage.getItem("selectedLocation")
        );
        setValue("location_id", locationOption?.value);
      } else {
        setValue("location_id", locations?.data[0].id);
      }
    }
  }, [locations?.data, setValue]);

  const getModalTitle = () => {
    return "Add User";
  };

  return (
    <Modal
      open={addModalOpen}
      onClose={() => {
        onClose();
        // resetForm()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {getModalTitle()}
          </Typography>

          <TextField
            {...register("name")}
            error={!!errors.name}
            helperText={!!errors.name && errors.name?.message}
            label="Name"
            placeholder="Enter name"
            fullWidth
            variant="filled"
            inputProps={{ maxLength: 64 }}
            sx={editUserStyles.inputStyle}
          />

          <TextField
            error={!!errors.email}
            helperText={!!errors.email && errors.email?.message}
            label="Email"
            placeholder="Enter e-mail"
            fullWidth
            variant="filled"
            sx={editUserStyles.inputStyle}
            inputProps={{ maxLength: 64 }}
            {...register("email")}
          />

          {user.roleId > Roles.RoleManager && (
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth error={!!errors?.location_id}>
                <InputLabel id="location_id">Location</InputLabel>

                <Controller
                  control={control}
                  name="location_id"
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        data-testid="role-id"
                        sx={editUserStyles.inputStyle}
                        id="location_id"
                        variant="filled"
                      >
                        {locations?.data.map((location) => (
                          <MenuItem key={location.id} value={location.id}>
                            {location.name}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
                <FormHelperText>{errors?.location_id?.message}</FormHelperText>
              </FormControl>
            </Box>
          )}

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth error={!!errors?.role_id}>
              <InputLabel id="role_id">Role</InputLabel>

              <Controller
                control={control}
                name="role_id"
                render={({ field }) => {
                  return (
                    <Select
                      data-testid="location-id"
                      {...field}
                      sx={editUserStyles.inputStyle}
                      id="role_id"
                      variant="filled"
                    >
                      {ROLES.map((role) => {
                        return (
                          <MenuItem
                            key={role.id}
                            value={role.id}
                            sx={{
                              display:
                                user.roleId < role.id ? "none" : "inherit",
                            }}
                          >
                            {role.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  );
                }}
              />
              <FormHelperText>{errors?.role_id?.message}</FormHelperText>
            </FormControl>
          </Box>

          <Controller
            control={control}
            name="enabled"
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label="Enable"
              />
            )}
          />

          <Controller
            control={control}
            name="notify"
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label="Notify"
              />
            )}
          />

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              type="submit"
              variant="contained"
              loading={false}
            >
              Save
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default AddUser;
