import { UseMutateFunction, useMutation, useQueryClient } from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";
import toast from "react-hot-toast";
import {ProductGroup} from "types";

interface AddGroupParams {
  group: ProductGroup;
}

export function useAddGroup(): UseMutateFunction<
  any,
  unknown,
  AddGroupParams,
  unknown
> {
  const { axiosInstance, getJWTHeader } = useAxios();
  const { user } = useUser();
  const queryClient = useQueryClient();

  async function addGroup(group: ProductGroup) {
    if (!group) return null;

    const res = await axiosInstance({
      url: `/product-groups`,
      method: "POST",
      data: group,
      headers: getJWTHeader(user),
    });

    return res;
  }

  const { mutate: mutateAddGroup } = useMutation(
    ({ group }: { group: ProductGroup }) => addGroup(group),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.productGroups]);
        toast.success("New product group added successfully");
      },
    }
  );

  return mutateAddGroup;
}
