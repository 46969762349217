import React from "react";
import { useEffect, useState } from "react";

import { Box, Container, Grid, Link } from "@mui/material";
import FileSaver from "file-saver";
import CustomTable from "../../components/Table/Table";
import { ROWS_PER_PAGE } from "../../helpers";
import LocationSelector from "../../components/LocationSelector/LocationSelector";
import { useUser } from "../user/hooks/useUser";
import { useLocations } from "../locations/hooks/useLocations";
import { useProductJobs } from "./hooks/useProductJobs";
import useAxios from "../../axiosInstance";

const ProductJobs = () => {
  const { user } = useUser();
  const { axiosInstance, getJWTHeader } = useAxios();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [locationOptions, setLocationOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: number;
  }>({ label: "", value: 0 });

  const jobs = useProductJobs({
    p: page + 1,
    lm: rowsPerPage,
    l: selectedOption?.value,
  });

  const totalRows = jobs?.total_records;
  const locations = useLocations({
    p: 1,
    lm: 50,
  });
  const data = jobs?.data?.length > 0 ? jobs.data : jobs;

  useEffect(() => {
    if (locations?.data) {
      setLocationOptions(
        locations?.data.map((location) => ({
          label: location.name,
          value: location.id,
        }))
      );
      let locationOption;
      if (localStorage.getItem("selectedLocation")) {
        locationOption = JSON.parse(localStorage.getItem("selectedLocation"));
      } else {
        locationOption = {
          label: locations?.data[0]?.name,
          value: locations?.data[0]?.id,
        };
      }
      setSelectedOption(locationOption);
    }
  }, [locations?.data]);

  const onLocationChange = (location) => {
    setSelectedOption(location);
    localStorage.setItem("selectedLocation", JSON.stringify(location));
  };

  const downloadFile = (filename: string, type: string) => {
    axiosInstance({
      url: `/files/${filename}?t=i`,
      method: "GET",
      headers: getJWTHeader(user),
      responseType: "blob",
    }).then((response) => {
      const reader = new FileReader();
      reader.readAsText(response.data);

      reader.onload = function () {
        const res = JSON.parse(reader.result as string);

        var blob = new Blob([atob(res.data)], {
          type: "text/plain;charset=utf-8",
        });
        FileSaver.saveAs(blob, filename);
      };

      reader.onerror = function () {
        console.log(reader.error);
      };
    });
  };

  const onPageChange = (value) => {
    setPage(value);
  };

  const columns = [
    {
      field: "type",
      headerName: "Type",
      flex: 1.2,
    },
    {
      field: "filename",
      headerName: "Filename",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <Link
            href="#"
            onClick={() => downloadFile(params.value, params.row.type)}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      maxWidth: 85,
      flex: 1,
    },
    {
      field: "error",
      headerName: "Error",
      flex: 1.7,
    },
    {
      field: "process_date",
      headerName: "Process Date",
      minWidth: 120,
      flex: 1,
      valueFormatter: (params) =>
        params?.value ? new Date(params?.value).toLocaleString() : "-",
    },
  ];

  return (
    <Container maxWidth={false} disableGutters={false} component="main">
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={4}>
            <LocationSelector
              value={selectedOption}
              onChange={onLocationChange}
              options={locationOptions}
            />
          </Grid>
        </Grid>
        {jobs && (
          <CustomTable
            onPageSizeChange={(value) => {
              setRowsPerPage(value);
            }}
            paginationMode="server"
            pagination
            page={page}
            pageSize={rowsPerPage}
            onPageChange={onPageChange}
            rowCount={totalRows}
            columns={columns}
            rows={data}
            title={"Product Jobs"}
          />
        )}
      </Box>
    </Container>
  );
};

export default ProductJobs;
