import { useQuery } from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";

const fallback = null;

export function useProduct(productId: string, locationId: number) {
  const { user } = useUser();
  const { axiosInstance, getJWTHeader } = useAxios();

  async function getProduct() {
    if (!productId) return null;
    const { data } = await axiosInstance({
      url: `/v2//products/${productId}?l=${locationId}`,
      method: "GET",
      headers: getJWTHeader(user),
    });

    return data;
  }

  const { data = fallback } = useQuery(
    [queryKeys.product, productId],
    () => getProduct(),
    {
      staleTime: 0,
      cacheTime: 0, // 5 minutes
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    }
  );

  return data;
}
