import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Box, Container, Grid } from "@mui/material";
import React from "react";
import DashboardCard from "../../components/DashboardCard/DashboardCard";
import CustomTable from "../../components/Table/Table";
import { formatter } from "../../helpers";
import { numberWithCommas } from "../../helpers/constants";
import { useDashboard } from "./hooks/useDashboard";

const columns = [
  {
    field: "create_date",
    headerName: "Date",
    maxWidth: 250,
    flex: 1.5,
    valueFormatter: (params) =>
      params?.value ? new Date(params?.value).toLocaleString() : "-",
  },
  {
    field: "id",
    headerName: "ID",
    headerAlign: "right",
    align: "right",
    flex: 1,
  },
  {
    field: "card",
    headerName: "Card",
    maxWidth: 200,
    flex: 2,
  },
  {
    field: "order_amount",
    headerName: "$ Order",
    headerAlign: "right",
    align: "right",
    flex: 1,
    renderCell: (params) => <>{formatter.format(params.value)}</>,
  },
  {
    field: "auth_amount",
    headerName: "$ Auth",
    headerAlign: "right",
    align: "right",
    flex: 1,
    renderCell: (params) => <>{formatter.format(params.value)}</>,
  },
  {
    field: "type",
    headerName: "Type",
    maxWidth: 100,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 2,
  },
];

const Dashboard = () => {
  const dashboard = useDashboard();

  if (!dashboard) return null;

  return (
    <Container maxWidth={false} disableGutters={false} component="main">
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <DashboardCard
              icon={<MonetizationOnIcon sx={{ fontSize: 50 }} />}
              header={formatter.format(dashboard?.daily_sales)}
              text="Current Day Sales"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <DashboardCard
              icon={<SupportAgentIcon sx={{ fontSize: 50 }} />}
              header={numberWithCommas(dashboard?.daily_customers)}
              text="Current Day Customers"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <DashboardCard
              icon={<MonetizationOnIcon sx={{ fontSize: 50 }} />}
              header={formatter.format(dashboard?.monthly_sales)}
              text="Current Month Sales"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <DashboardCard
              icon={<PeopleAltIcon sx={{ fontSize: 50 }} />}
              header={numberWithCommas(dashboard?.monthly_customers)}
              text="Current Month Customers"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <DashboardCard
              icon={<MonetizationOnIcon sx={{ fontSize: 50 }} />}
              header={formatter.format(dashboard?.total_sales)}
              text="Total Sales"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <DashboardCard
              icon={<PeopleAltIcon sx={{ fontSize: 50 }} />}
              header={numberWithCommas(dashboard?.total_customers)}
              text="Total Customers"
            />
          </Grid>
        </Grid>

        <CustomTable
          hideFooterPagination={true}
          columns={columns}
          rows={dashboard?.latest_txs}
          title={"Latest Transactions"}
        />
      </Box>
    </Container>
  );
};

export default Dashboard;
