import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { addProductStyles } from "./styles";
import { ProductGroup } from "../../types";
import { useAddGroup } from "./hooks/useAddGroup";
import { useEditGroup } from "./hooks/useEditGroup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type FormValues = {
  name: string;
  tax_rate: number;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  tax_rate: Yup.number()
    .positive()
    .required("Tax rate is required")
    .typeError("Tax rate is required"),
});

interface AddGroupProps {
  locationId: number;
  edit: boolean;
  groupId?: number | null;
  openAddGroupModal: boolean;
  group: ProductGroup;
  setOpenAddGroupModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddGroup = ({
  locationId,
  edit,
  groupId = null,
  openAddGroupModal = false,
  group,
  setOpenAddGroupModal,
}: AddGroupProps) => {
  const mutateAddGroup = useAddGroup();
  const mutateEditGroup = useEditGroup();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "all",
    defaultValues: {
      name: "",
      tax_rate: 0.0,
    },
  });

  useEffect(() => {
    if (edit === true) {
      setValue("name", group?.name);
      setValue("tax_rate", group?.tax_rate);
    } else {
      setValue("name", "");
      setValue("tax_rate", 0.0);
    }
  }, [group, setValue, edit, openAddGroupModal]);

  const onClose = () => {
    reset({});
    setOpenAddGroupModal(false);
  };

  const onSubmit = (values) => {
    const updatedValues = {
      ...values,
      tax_rate: parseFloat(values.tax_rate.toFixed(2)),
      location_id: locationId,
    };

    if (edit === true) {
      mutateEditGroup({ groupId, group: updatedValues });
    } else {
      mutateAddGroup({ group: updatedValues });
    }
    onClose();
  };

  if (edit === true && !group) return null;

  return (
    <Modal
      open={openAddGroupModal}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {edit === true ? "Edit Group" : "Add Group"}
          </Typography>
          <TextField
            {...register("name")}
            error={!!errors.name}
            helperText={errors.name && errors.name?.message}
            label="Name"
            placeholder="Enter name"
            fullWidth
            required
            variant="filled"
            inputProps={{ maxLength: 64 }}
            sx={addProductStyles.inputStyle}
          />
          <TextField
            {...register("tax_rate")}
            error={!!errors.tax_rate}
            type="number"
            inputProps={{
              maxLength: 4,
              step: 0.001,
              min: 0,
              max: 999,
            }}
            helperText={errors.tax_rate && errors.tax_rate?.message}
            label="Tax Rate"
            placeholder="Enter tax rate (ie. 7.25)"
            fullWidth={true}
            required
            variant="filled"
            sx={addProductStyles.inputStyle}
          />
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              type="submit"
              variant="contained"
              loading={false}
            >
              Save
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default AddGroup;
