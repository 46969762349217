import { UseMutateFunction, useMutation, useQueryClient } from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";
import toast from "react-hot-toast";

interface DeleteUserParams {
  userId: number;
}

export function useDeleteUser(): UseMutateFunction<
  any,
  unknown,
  DeleteUserParams,
  unknown
> {
  const { axiosInstance, getJWTHeader } = useAxios();
  const { user } = useUser();
  const queryClient = useQueryClient();

  async function deleteUser(userId) {
    if (!userId) return null;

    const res = await axiosInstance({
      url: `/users/${userId}`,
      method: "DELETE",
      headers: getJWTHeader(user),
    });

    return res;
  }

  const { mutate: mutateDeleteUser } = useMutation(
    ({ userId }: { userId: number }) => deleteUser(userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.users]);
        toast.success("User deleted successfully");
      },
    }
  );

  return mutateDeleteUser;
}
