import React from "react";
import { Box, Container, Grid, Link } from "@mui/material";
import { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar/SearchBar";
import CustomTable from "../../components/Table/Table";
import { formatter, ROWS_PER_PAGE } from "../../helpers";
import OrderDetail from "./DetailOrder";
import LocationSelector from "../../components/LocationSelector/LocationSelector";
import { useOrders } from "./hooks/useOrders";
import { useLocations } from "../locations/hooks/useLocations";

const searchFields = [
  {
    id: 1,
    value: "id",
    label: "Order ID",
  },
];

const Orders = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [colSearch, setColSearch] = useState("id");
  const [valSearch, setValSearch] = useState("");
  const [orderId, setOrderId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const orders = useOrders({
    p: page + 1,
    lm: rowsPerPage,
    c: colSearch,
    s: valSearch,
    l: selectedOption?.value,
  });

  const totalRows = orders?.total_records;
  // const locations = useLocations({});
  const locations = useLocations({
    p: 1,
    lm: 50,
  });

  const columns = [
    {
      field: "id",
      headerName: "Order ID",
      maxWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Link href="#" onClick={() => openDetailModal(params.id)}>
          {params.value}
        </Link>
      ),
    },
    {
      field: "sale_date",
      headerName: "Sale Date",
      maxWidth: 200,
      flex: 1,
      valueFormatter: (params) =>
        params?.value ? new Date(params?.value).toLocaleString() : "-",
    },
    {
      field: "total_amount",
      headerName: "$ Total",
      headerAlign: "right",
      align: "right",
      maxWidth: 150,
      flex: 1,
      renderCell: (params) => <>{formatter.format(params.value)}</>,
    },
    {
      field: "voided",
      headerName: "Void",
      maxWidth: 150,
      flex: 1,
    },
  ];

  const data = orders?.data?.length > 0 ? orders.data : orders;

  useEffect(() => {
    if (locations?.data) {
      setLocationOptions(
        locations?.data.map((location) => ({
          label: location.name,
          value: location.id,
        }))
      );
      let locationOption;
      if (localStorage.getItem("selectedLocation")) {
        locationOption = JSON.parse(localStorage.getItem("selectedLocation"));
      } else {
        locationOption = {
          label: locations?.data[0]?.name,
          value: locations?.data[0]?.id,
        };
      }
      setSelectedOption(locationOption);
    }
  }, [locations?.data]);

  const openDetailModal = (orderId) => {
    setOrderId(orderId);
    setModalOpen(true);
  };

  const onLocationChange = (location) => {
    setSelectedOption(location);
    localStorage.setItem("selectedLocation", JSON.stringify(location));
  };

  const onPageChange = (value) => {
    setPage(value);
  };

  return (
    <Container maxWidth={false} disableGutters={false} component="main">
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={8}>
            <SearchBar
              searchFields={searchFields}
              setColSearc={setColSearch}
              setValSearch={setValSearch}
            />
          </Grid>
          <Grid item xs={4}>
            <LocationSelector
              value={selectedOption}
              onChange={onLocationChange}
              options={locationOptions}
            />
          </Grid>
        </Grid>

        {data && (
          <CustomTable
            onPageSizeChange={(value) => {
              setRowsPerPage(value);
            }}
            paginationMode="server"
            pagination
            page={page}
            pageSize={rowsPerPage}
            onPageChange={onPageChange}
            rowCount={totalRows ?? 0}
            columns={columns}
            rows={data}
            title={"Orders"}
          />
        )}

        <OrderDetail
          orderId={orderId}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      </Box>
    </Container>
  );
};

export default Orders;
