import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Controller, useForm } from "react-hook-form";
import { ROLES } from "../../helpers/constants";
import * as Yup from "yup";
import { editUserStyles } from "./styles";
import { useSingleUser } from "./hooks/useSingleUser";
import { useUser } from "../user/hooks/useUser";
import { useEditUser } from "./hooks/useEditUser";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface EditUserProps {
  userId: number | null;
  editModalOpen: boolean;
  setEditModalOpen: (open: boolean) => void;
}

interface UserFormData {
  name: string;
  email: string;
  role: number;
  enabled: boolean;
}

const EditUser = ({
  userId = null,
  editModalOpen = false,
  setEditModalOpen,
}: EditUserProps) => {
  const mutateEditUser = useEditUser();
  const { user: loggedInUser } = useUser();
  const user = useSingleUser(userId);

  const onClose = () => {
    setEditModalOpen(false);
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required!"),
    email: Yup.string().email("Incorrect email").required("Email required"),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
    mode: "all",
    defaultValues: {},
  });

  useEffect(() => {
    setValue("name", user?.name);
    setValue("email", user?.email);
    setValue("enabled", user?.enabled);
  }, [user, setValue]);

  useEffect(() => {
    if (user?.role_id && ROLES?.length > 0) {
      setValue("role", user?.role_id);
    }
  }, [user, setValue]);

  const onSubmit = (values: UserFormData) => {
    const body = {
      name: values.name,
      email: values.email,
      role_id: values.role,
      enabled: values.enabled,
    };

    mutateEditUser({
      userId,
      userData: body,
    });
    onClose();
  };
  const getModalTitle = () => {
    return "Edit User";
  };

  if (!user) {
    return;
  }

  return (
    <Modal
      open={editModalOpen}
      onClose={() => {
        onClose();
        // resetForm()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {getModalTitle()}
          </Typography>
          {user?.name && (
            <TextField
              {...register("name")}
              error={!!errors.name}
              helperText={errors.name && errors.name?.message}
              label="Name"
              placeholder="Enter name"
              fullWidth
              required
              variant="filled"
              sx={editUserStyles.inputStyle}
            />
          )}
          {user?.email && (
            <TextField
              error={!!errors.email}
              helperText={errors.email && errors.email?.message}
              label="Email"
              placeholder="Enter e-mail"
              fullWidth
              required
              variant="filled"
              sx={editUserStyles.inputStyle}
              {...register("email")}
            />
          )}

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="role">Role</InputLabel>

              {user?.role_id >= 0 && ROLES?.length > 0 && (
                <Controller
                  control={control}
                  name="role"
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        sx={editUserStyles.inputStyle}
                        id="role"
                        variant="filled"
                      >
                        {ROLES.map((role) => {
                          return (
                            <MenuItem
                              key={role.id}
                              value={role.id}
                              sx={{
                                display:
                                  loggedInUser.roleId < role.id ? "none" : "inherit",
                              }}
                            >
                              {role.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    );
                  }}
                />
              )}
            </FormControl>
          </Box>

          <Controller
            control={control}
            name="enabled"
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label="Enable"
              />
            )}
          />

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              type="submit"
              variant="contained"
              loading={false}
            >
              Save
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default EditUser;
