import React from "react";
import { Box, Container, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";

export default function Maintenance() {
  const [countdown, setCountdown] = useState(300); // 300 seconds = 5 minutes
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((countdown) => countdown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      navigate("/dashboard");
    }
  }, [countdown, navigate]);

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
        display: "flex",
        justifyContent: "center",
        minHeight: "100vh",
        color: "#fff",
        marginTop: "15px",
        margin: "0px",
        paddingLeft: "0px !important",
      }}
    >
      <Container sx={{ paddingLeft: "0px !important" }}>
        <Box
          mt={5}
          sx={{ display: "flex", justifyContent: "center" }}
          mb={4}
          mr={3}
        >
          <img src={Logo} alt="" style={{ width: "70%" }} />
        </Box>
        <Box
          mt={10}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant="h3">Maintenance Mode</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h5" mx={10} mt={10}>
            The server is currently undergoing maintenance.
            <br />
            Will resume as soon as maintenance is completed.
            <br />
            Waiting 5min to check again...
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h3" mx={10} mt={10}>
            {countdown}
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
}
