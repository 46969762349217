import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Stack,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";

import LocationSelector from "../../components/LocationSelector/LocationSelector";
import CustomTable from "../../components/Table/Table";
import { ROWS_PER_PAGE } from "../../helpers";
import { useLocations } from "../locations/hooks/useLocations";
import AddGroup from "./addGroup";
import { useGroups } from "./hooks/useGroups";
import { ProductGroup } from "../../types";
import { useDeleteGroupProduct } from "./hooks/useDeleteGroup";

const ProductCategories = () => {
  const [page, setPage] = useState(0);
  const [editCategory, setEditCategory] = useState(false);
  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);
  const [category, setCategory] = useState({} as ProductGroup);
  const mutateDeleteGroupProduct = useDeleteGroupProduct();

  const [locationOptions, setLocationOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: number;
  }>({ label: "", value: 0 });
  const locations = useLocations({
    p: 1,
    lm: 50,
  });
  const categories = useGroups(selectedOption?.value);
  const totalRows = categories?.total_records;
  const data = categories?.data?.length > 0 ? categories.data : categories;

  const openAddModal = () => {
    setEditCategory(false);
    setOpenAddCategoryModal(true);
    setCategory({} as ProductGroup);
  };

  const openEditModal = (category: ProductGroup) => {
    setCategory(category);
    setEditCategory(true);
    setOpenAddCategoryModal(true);
  };

  useEffect(() => {
    if (locations?.data) {
      setLocationOptions(
        locations?.data.map((location) => ({
          label: location.name,
          value: location.id,
        }))
      );
      let locationOption;
      if (localStorage.getItem("selectedLocation")) {
        locationOption = JSON.parse(localStorage.getItem("selectedLocation"));
      } else {
        locationOption = {
          label: locations?.data[0]?.name,
          value: locations?.data[0]?.id,
        };
      }
      setSelectedOption(locationOption);
    }
  }, [locations?.data]);

  const onLocationChange = (location) => {
    setSelectedOption(location);
    localStorage.setItem("selectedLocation", JSON.stringify(location));
  };

  const onPageChange = (value) => {
    setPage(value);
  };

  const deleteProductGroup = (itemId: number) => {
    mutateDeleteGroupProduct({ id: itemId });
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      renderCell: (params) => {
        return (
          <Link href="#" onClick={() => openEditModal(params.row)}>
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "tax_rate",
      padding: 15,
      headerName: "Tax Rate %",
      headerAlign: "right",
      align: "right",
      flex: 1,
      renderCell: (params) => <>{params.value.toFixed(2)}</>,
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      getActions: (params) => [
        <Tooltip title="Delete Group">
          <GridActionsCellItem
            icon={<DeleteIcon />}
            onClick={() => deleteProductGroup(params.id)}
            label="Delete Group"
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <Container maxWidth={false} disableGutters={false} component="main">
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <LocationSelector
              value={selectedOption}
              onChange={onLocationChange}
              options={locationOptions}
            />
          </Grid>
        </Grid>

        <Stack direction="row" spacing={1} justifyContent="flex-end" mt={5}>
          <Button size="small" variant="contained" onClick={openAddModal}>
            Add
          </Button>
        </Stack>
        {data && (
          <CustomTable
            paginationMode="server"
            pagination
            page={page}
            pageSize={100}
            onPageChange={onPageChange}
            rowCount={totalRows ?? 0}
            rowsPerPageOptions={[ROWS_PER_PAGE]}
            columns={columns}
            rows={data}
            title={"Product Groups"}
          />
        )}

        <AddGroup
          locationId={selectedOption.value}
          edit={editCategory}
          groupId={category?.id}
          openAddGroupModal={openAddCategoryModal}
          group={category}
          setOpenAddGroupModal={setOpenAddCategoryModal}
        />
      </Box>
    </Container>
  );
};

export default ProductCategories;
