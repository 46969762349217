import { UseMutateFunction, useMutation, useQueryClient } from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";
import toast from "react-hot-toast";
import { ProductGroup } from "types";

interface EditGroupParams {
  groupId: number;
  group: ProductGroup;
}

export function useEditGroup(): UseMutateFunction<
  any,
  unknown,
  EditGroupParams,
  unknown
> {
  const { axiosInstance, getJWTHeader } = useAxios();
  const { user } = useUser();
  const queryClient = useQueryClient();

  async function editGroup(groupId, group) {
    if (!group) return null;

    const res = await axiosInstance({
      url: `/product-groups/${groupId}`,
      method: "PUT",
      data: group,
      headers: getJWTHeader(user),
    });

    return res;
  }

  const { mutate: mutateEditGroup } = useMutation(
    ({ groupId, group }: { groupId: number; group: ProductGroup }) =>
      editGroup(groupId, group),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.productGroups]);
        queryClient.invalidateQueries([queryKeys.productGroup]);
        toast.success("Product group updated successfully");
      },
    }
  );

  return mutateEditGroup;
}
