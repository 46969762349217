import { useQuery } from "@tanstack/react-query";
import { ReportsData } from "types";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";

const fallback = null;

interface UseJobsProps {
  p?: number;
  l?: number;
  lm?: number;
}

export function useProductJobs({
  p = 1,
  l = 0,
  lm = 20,
}: UseJobsProps): ReportsData {
  const { user } = useUser();
  const { axiosInstance, getJWTHeader } = useAxios();

  async function getJobs() {
    const { data } = await axiosInstance({
      url: `/product-jobs?l=${l}&p=${p}&lm=${lm}`,
      method: "GET",
      headers: getJWTHeader(user),
    });

    return data;
  }

  const { data = fallback } = useQuery(
    [queryKeys.productJobs, p, l, lm],
    () => getJobs(), {
    enabled: l !== 0
  }
  );

  return data;
}
