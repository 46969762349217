import React, { ReactElement } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

interface DashboardCardProps {
  icon: ReactElement;
  header: string;
  text: string;
}

export default function DashboardCard({
  icon,
  header,
  text,
}: DashboardCardProps) {
  return (
    <Card
      sx={{
        minWidth: "50px",
        width: "-webkit-fill-available",
        height: { xm: "fit-content", sm: 150 },
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Box>{icon}</Box>
        </Box>
        <Typography variant="h5" component="div">
          {header}
        </Typography>
        <Typography variant="body2">{text}</Typography>
      </CardContent>
    </Card>
  );
}
