import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { editLocationStyles, style } from "./styles";
import InputMask from "react-input-mask";
import { useLocation } from "./hooks/useLocation";
import { useUpdateLocation } from "./hooks/useUpdateLocation";

interface EditLocationProps {
  locationId?: number | null;
  modalOpen?: boolean;
  setModalOpen: (modalOpen: boolean) => void;
}

type FormValues = {
  name: string;
  city: string;
  contact: string;
  email: string;
  address: string;
  state: string;
  zip: string;
  phone: string;
};

export const EditLocation = ({
  locationId = null,
  modalOpen = false,
  setModalOpen,
}: EditLocationProps) => {
  const location = useLocation(locationId);
  const mutateLocation = useUpdateLocation();

  const onClose = () => {
    setModalOpen(false);
  };

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (
      value?.endsWith("-") &&
      userInput !== "-" &&
      !watchZipField?.endsWith("-")
    ) {
      if (cursorPosition === value?.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required!"),
    city: Yup.string().required("City is required!"),
    contact: Yup.string().required("Contact is required!"),
    email: Yup.string().required("Email is required!"),
    address: Yup.string().required("Address is required!"),
    state: Yup.string().max(2).required("State is required!"),
    zip: Yup.string().required("Zip is required!"),
    phone: Yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "all",
    defaultValues: {
      name: location?.name,
      email: location?.email,
      phone: location?.phone,
      address: location?.address,
      city: location?.city,
      state: location?.state,
      zip: location?.zip,
      contact: location?.contact,
    },
  });

  const watchZipField = watch("zip");
  const watchStateField = watch("state");

  useEffect(() => {
    if (watchStateField?.length > 0) {
      setValue("state", watchStateField.toUpperCase());
    }
  }, [watchStateField, setValue]);

  useEffect(() => {
    setValue("name", location?.name);
    setValue("email", location?.email);
    setValue("phone", location?.phone);
    setValue("address", location?.address);
    setValue("city", location?.city);
    setValue("state", location?.state);
    setValue("zip", location?.zip);
    setValue("contact", location?.contact);
  }, [location, setValue]);

  const onSubmit = (values) => {
    const phone = values.phone.replace(/[^\d]/g, "");
    const zip = values.zip.replace(/[^\d]/g, "");
    const body = {
      name: values.name,
      tax_rate: 0,
      city: values.city,
      contact: values.contact,
      email: values.email,
      address: values.address,
      zip: zip,
      phone: phone,
      state: values.state,
      catalog_id: values.catalog_id,
    };

    mutateLocation({ newLocation: body, locationId });
    onClose();
  };

  if (!location) return null;

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        onClose();
        // resetForm()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Location
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                {...register("name")}
                error={!!errors.name}
                helperText={errors.name && errors.name?.message}
                label="Name"
                placeholder="Enter name"
                fullWidth
                required
                variant="filled"
                inputProps={{ maxLength: 128 }}
                sx={editLocationStyles.inputStyle}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register("contact")}
                error={!!errors.contact}
                helperText={errors.contact && errors.contact?.message}
                label="Contact"
                placeholder="Enter contact"
                fullWidth
                required
                variant="filled"
                inputProps={{ maxLength: 64 }}
                sx={editLocationStyles.inputStyle}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register("email")}
                error={!!errors.email}
                type="email"
                helperText={errors.email && errors.email?.message}
                label="E-mail"
                placeholder="Enter email"
                fullWidth
                required
                variant="filled"
                inputProps={{ maxLength: 64 }}
                sx={editLocationStyles.inputStyle}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <InputMask mask="(999) 999-9999" {...field}>
                    {() => (
                      <TextField
                        error={!!errors.phone}
                        type="tel"
                        helperText={errors.phone && errors.phone?.message}
                        label="Phone"
                        placeholder="Enter phone"
                        fullWidth
                        required
                        variant="filled"
                        sx={editLocationStyles.inputStyle}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register("address")}
                error={!!errors.address}
                multiline
                rows={1}
                helperText={errors.address && errors.address?.message}
                label="Address"
                placeholder="Enter address"
                fullWidth
                required
                variant="filled"
                inputProps={{ maxLength: 128 }}
                sx={editLocationStyles.inputStyle}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                {...register("city")}
                error={!!errors.city}
                helperText={errors.city && errors.city?.message}
                label="City"
                placeholder="Enter city"
                fullWidth
                required
                variant="filled"
                inputProps={{ maxLength: 64 }}
                sx={editLocationStyles.inputStyle}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                {...register("state")}
                error={!!errors.state}
                helperText={errors.state && errors.state?.message}
                label="State"
                placeholder="Enter state"
                fullWidth
                required
                variant="filled"
                inputProps={{ maxLength: 2 }}
                sx={editLocationStyles.inputStyle}
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                name="zip"
                control={control}
                render={({ field }) => (
                  <InputMask
                    mask="99999-9999"
                    maskChar={null}
                    beforeMaskedValueChange={beforeMaskedValueChange}
                    {...field}
                  >
                    {() => (
                      <TextField
                        error={!!errors.zip}
                        helperText={errors.zip && errors.zip?.message}
                        label="Zip"
                        placeholder="Enter zip"
                        fullWidth
                        required
                        variant="filled"
                        sx={editLocationStyles.inputStyle}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
                <LoadingButton
                  color="primary"
                  type="submit"
                  variant="contained"
                  loading={false}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};
