import { UseMutateFunction, useMutation, useQueryClient } from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";
import toast from "react-hot-toast";
import { Product } from "types";

interface AddProductParams {
  newProduct: Product;
}

export function useAddProduct(): UseMutateFunction<
  any,
  unknown,
  AddProductParams,
  unknown
> {
  const { axiosInstance, getJWTHeader } = useAxios();
  const { user } = useUser();
  const queryClient = useQueryClient();

  async function addProduct(newProduct: Product) {
    if (!newProduct) return null;

    const res = await axiosInstance({
      url: `/v2/products`,
      method: "POST",
      data: newProduct,
      headers: getJWTHeader(user),
    });

    return res;
  }

  const { mutate: mutateAddProduct } = useMutation(
    ({ newProduct }: { newProduct: Product }) => addProduct(newProduct),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.products]);
        toast.success("New Product added successfully");
      },
    }
  );

  return mutateAddProduct;
}
