import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Modal,
  Stack,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { addProductStyles } from "./styles";
import { useAddProduct } from "./hooks/useAddProduct";
import { useEditProduct } from "./hooks/useEditProduct";
import { useGroups } from "./hooks/useGroups";
import MenuItem from "@mui/material/MenuItem";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type FormValues = {
  upc: string;
  name: string;
  price: number;
  taxable: boolean;
  group_id: number;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required!"),
  price: Yup.number()
    .positive()
    .required("Define Price")
    .typeError("Define Price"),
  upc: Yup.string().required("Enter UPC code").typeError("Enter UPC code"),
});

interface AddProductProps {
  locationId: number;
  edit: boolean;
  productId?: number | null;
  openAddProductModal: boolean;
  product: any;
  setOpenAddProductModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddProduct = ({
  locationId,
  edit,
  productId = null,
  openAddProductModal = false,
  product,
  setOpenAddProductModal,
}: AddProductProps) => {
  const mutateAddProduct = useAddProduct();
  const mutateEditProduct = useEditProduct();
  const groups = useGroups(locationId);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "all",
    defaultValues: {
      name: "",
      price: 0.0,
      upc: "",
      taxable: true,
      group_id: 0,
    },
  });

  useEffect(() => {
    if (edit === true) {
      setValue("name", product?.name);
      setValue("price", product?.price);
      setValue("upc", product?.upc);
      setValue("taxable", product?.taxable);
    } else {
      setValue("name", "");
      setValue("price", 0.0);
      setValue("upc", "");
      setValue("taxable", false);
    }
  }, [product, setValue, edit, openAddProductModal]);

  useEffect(() => {
    if (groups?.length > 0) {
      setValue("group_id", product?.group_id);
    }
  }, [product, groups]);

  const onClose = () => {
    reset({});
    setOpenAddProductModal(false);
  };

  const onSubmit = (values) => {
    const updatedValues = {
      ...values,
      price: parseFloat(values.price.toFixed(2)),
      location_id: locationId,
      upc: "" + values.upc,
    };

    if (edit === true) {
      mutateEditProduct({ productData: updatedValues });
    } else {
      mutateAddProduct({ newProduct: updatedValues });
    }
    onClose();
  };

  if (edit === true && !product) return null;

  return (
    <Modal
      open={openAddProductModal}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {edit === true ? "Edit Product" : "Add Product"}
          </Typography>

          <TextField
            {...register("upc")}
            error={!!errors.upc}
            type="text"
            helperText={errors.upc && errors.upc?.message}
            label="UPC"
            placeholder="Enter upc"
            fullWidth={true}
            required
            variant="filled"
            autoFocus={true}
            inputProps={{ maxLength: 14 }}
            sx={addProductStyles.inputStyle}
          />
          <TextField
            {...register("name")}
            error={!!errors.name}
            helperText={errors.name && errors.name?.message}
            label="Name"
            placeholder="Enter name"
            fullWidth
            required
            variant="filled"
            inputProps={{ maxLength: 64 }}
            sx={addProductStyles.inputStyle}
          />
          <TextField
            {...register("price")}
            error={!!errors.price}
            type="number"
            inputProps={{
              maxLength: 4,
              step: 0.001,
              min: 0,
              max: 999,
            }}
            helperText={errors.price && errors.price?.message}
            label="Price"
            placeholder="Enter price"
            fullWidth={true}
            required
            variant="filled"
            sx={addProductStyles.inputStyle}
          />

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="group_id">Category</InputLabel>
              {groups?.length > 0 && (
                <Controller
                  control={control}
                  name="group_id"
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        sx={addProductStyles.inputStyle}
                        id="group_id"
                        variant="filled"
                      >
                        {groups.map((group) => {
                          return (
                            <MenuItem
                              key={group.id}
                              value={group.id}
                              sx={{
                                display: product?.group_id < 1 ? "" : "inherit",
                              }}
                            >
                              {group.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    );
                  }}
                />
              )}
            </FormControl>
          </Box>

          {/* taxable */}
          <Controller
            control={control}
            name="taxable"
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label="Taxable"
              />
            )}
          />

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              type="submit"
              variant="contained"
              loading={false}
            >
              Save
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default AddProduct;
