import { blue, green, grey, yellow } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Palette {
    tableStripe: Palette["primary"];
  }
  interface PaletteOptions {
    tableStripe?: PaletteOptions["primary"];
  }
}

export const getDesignTokens = (mode: any): any => ({
  components: {
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: "#ffffff",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        footerContainer: {
          background: "#3e4246",
        },
        iconSeparator: {
          display: "none",
        },
        columnHeaderTitle: {
          color: "#ffffff",
          fontSize: "16px",
          background: "#3e4246",
        },
        columnHeaders: {
          background: "#3e4246",
        },
        footerCell: {
          background: "#3e4246",
        },
        root: {
          border: "2px solid #3e4246",
          borderRadius: "10px",
          "& .MuiDataGrid-cell:hover": {
            color: "blue",
          },
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          ...(mode === "dark" && { color: grey[400] }),
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            background: yellow[100],
          },
          borderRadius: "4px 4px 0px 0px",
          "&:before": {
            borderBottom: "none",
          },
          backgroundColor: blue[100],
          "&:hover": {
            backgroundColor: green[100],
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: blue[200],
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
          fontWeight: 600,
          borderRadius: 8.5,
          textTransform: "none",
          "&.Mui-disabled": {
            background: "rgba(0, 0, 0, 0.12)",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "1.7rem",
        },
      },
    },
  },

  typography: {
    fontFamily: "Roboto",
    footer: {
      fontSize: "0.9rem",
      fontFamily: "Helvetica Neue",
      letterSpacing: "0.5px",
      textTransform: "capitalize",
    },
    h1: {
      fontSize: "1.6rem",
      fontWeight: 600,
      letterSpacing: "0.5px",
      textTransform: "capitalize",
    },
    h6: {
      //Table titles
      fontSize: "1.6rem",
      fontWeight: 600,
      letterSpacing: "0.5px",
      textTransform: "capitalize",
    },
  },

  sizes: {},

  palette: {
    mode,
    ...(mode === "light"
      ? {
          background: {
            default: "#fffdfd",
          },
          // palette values for light mode
          primary: {
            main: "rgb(22,44,84)",
            light: "#21417C",
          },
          secondary: {
            main: "rgb(216,219,224)",
          },
          divider: grey[200],
          tableStripe: blue[50],
          text: {
            primary: grey[900],
            secondary: grey[800],
          },
        }
      : {
          background: {
            default: "#23242d",
          },
          // palette values for dark mode
          primary: {
            main: "rgb(22,44,84)",
            light: "#21417C",
          },
          secondary: {
            main: "rgb(216,219,224)",
          },
          divider: "#162c53",
          tableStripe: blue[100],
          text: {
            primary: "#fff",
            secondary: "#fff",
          },
        }),
  },
});
