import "./App.css";
import { ThemeProvider } from "@emotion/react";
import { createContext, useMemo, useState } from "react";
import { createTheme, CssBaseline } from "@mui/material";
import { getDesignTokens } from "./styles/dashboardTheme";
import { queryClient } from "./react-query/queryClient";
import MyRoutes from "./routes/routes";
import { Loading } from "./components/Loading/Loading";
import { Toaster } from "react-hot-toast";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  PersistQueryClientProvider,
  Persister,
} from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";

export const ColorModeContext = createContext<any>({});
export let persister: Persister;

persister = createSyncStoragePersister({
  storage: window.localStorage,
});

function App() {
  const [mode, setMode] = useState("light");
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const colorMode = useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister }}
        >
          <MyRoutes />
          <Loading />
          <Toaster />
          <ReactQueryDevtools />
        </PersistQueryClientProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
