import { useQuery } from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";

const fallback = null;

export function useGroups(locationId: number) {
  const { user } = useUser();
  const { axiosInstance, getJWTHeader } = useAxios();

  // console.log("getting product groups for location: " + locationId)
  async function getProductGroups() {
    if (!locationId) return null;
    const { data } = await axiosInstance({
      url: `/product-groups?l=${locationId}`,
      method: "GET",
      headers: getJWTHeader(user),
    });

    return data;
  }

  const { data = fallback } = useQuery(
    [queryKeys.productGroups, locationId],
    () => getProductGroups(), {
  }
  );

  return data;
}
