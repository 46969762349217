import React from "react";
import { Paper, Typography } from "@mui/material";

export default function NotFound() {
  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
        display: "flex",
        justifyContent: "center",
        minHeight: "100vh",
        color: "#fff",
        marginTop: "15px",
        margin: "0px",
        paddingLeft: "0px !important",
      }}
    >
      <Typography variant="h4" pt={4}>
        Oops. Page not found
      </Typography>
    </Paper>
  );
}
