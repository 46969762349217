import useAxios from "../../../axiosInstance";
import { useUser } from "../../user/hooks/useUser";
import { DeleteProduct } from "../../../types";

export function useDeleteProduct() {
  const { axiosInstance, getJWTHeader } = useAxios();
  const { user } = useUser();

  return async function deleteProduct(product: DeleteProduct, scope: String) {
    if (!product) return null;

    const res = await axiosInstance({
      url: `/products?s=${scope}`,
      method: "DELETE",
      data: product,
      headers: getJWTHeader(user),
    });

    return res;
  };
}
