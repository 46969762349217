import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as Yup from "yup";
import useAxios from "../../../axiosInstance";
import { useUser } from "../hooks/useUser";
import { forgotStyles } from "./styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type FormValues = {
  email: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Incorrect email").required("Email required"),
});

const ForgotPassword = ({ modal, toggle }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "all",
    defaultValues: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const { axiosInstance, getJWTHeader } = useAxios();
  const { user } = useUser();

  const onSubmit = async (data) => {
    setIsLoading(true);
    let header = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    const idToken = localStorage.getItem("idToken") || "";

    if (idToken !== "") {
      header["Authorization"] = `Bearer ${idToken}`;
    }
    try {
      const response = await axiosInstance.post(
        `/auth/forgot-password`,
        // `/auth/forgot-password?key=${process.env.REACT_APP_API_KEY}`,
        { email: data.email },
        {
          headers: getJWTHeader(user),
        }
      );
      if (response?.status === 200) {
        toast.success("Reset Password Email has been sent.");
        toggle();
      }
    } catch (err) {
      toast.error("Email is invalid or not found.");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      open={modal}
      onClose={toggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Forgot Password
          </Typography>
          <TextField
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email && errors.email?.message}
            label="Email"
            placeholder="Enter e-mail"
            fullWidth
            required
            variant="filled"
            sx={forgotStyles.inputStyle}
          />

          <LoadingButton
            type="submit"
            sx={forgotStyles.buttonStyle}
            variant="contained"
            fullWidth
            loading={isLoading}
          >
            Send Reset Link
          </LoadingButton>
        </form>
      </Box>
    </Modal>
  );
};

export default ForgotPassword;
