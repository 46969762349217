import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  LinearProgress,
  Link,
  Stack,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { GridActionsCellItem } from "@mui/x-data-grid";
import useAxios from "axiosInstance";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import LocationSelector from "../../components/LocationSelector/LocationSelector";
import SearchBar from "../../components/SearchBar/SearchBar";
import CustomTable from "../../components/Table/Table";
import { ROWS_PER_PAGE, formatter } from "../../helpers";
import { baseURL, csvFilePath } from "../../helpers/constants";
import { useLocations } from "../locations/hooks/useLocations";
import { useUser } from "../user/hooks/useUser";
import AddProduct from "./AddProduct";
import { useDeleteProduct } from "./hooks/useDeleteProduct";
import { useProduct } from "./hooks/useProduct";
import { useProducts } from "./hooks/useProducts";
import { queryClient } from "react-query/queryClient";
import { queryKeys } from "react-query/constants";

const searchFields = [
  {
    id: 1,
    value: "name",
    label: "Name",
  },
  {
    id: 2,
    value: "upc",
    label: "UPC",
  },
];

const Products = () => {
  const { axiosInstance, getJWTHeader } = useAxios();
  const { user } = useUser();
  const deleteProduct = useDeleteProduct();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [colSearch, setColSearch] = useState(searchFields[0]?.value);
  const [valSearch, setValSearch] = useState("");
  const [editProduct, setEditProduct] = useState(false);
  const [open, setOpen] = useState(false);
  const [productId, setProductId] = useState(null);
  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [withCheckDigit, setWithCheckDigit] = useState(true);
  const [locationOptions, setLocationOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [selectedLocation, setSelectedLocation] = useState<{
    label: string;
    value: number;
  }>({ label: "", value: 0 });
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const product = useProduct(productId, selectedLocation?.value);
  const locations = useLocations({
    p: 1,
    lm: 50,
  });
  const inputRef = useRef(null);
  const products = useProducts({
    p: page + 1,
    lm: rowsPerPage,
    c: colSearch,
    s: valSearch,
    l: selectedLocation?.value,
  });

  const totalRows = products?.total_records;
  const data = products?.data;

  const handleChangeWithDigit = (event) => {
    setWithCheckDigit(event.target.checked);
  };

  const openAddModal = () => {
    setEditProduct(false);
    setOpenAddProductModal(true);
  };
  const openEditModal = (productId) => {
    setProductId(productId);
    setEditProduct(true);
    setOpenAddProductModal(true);
  };

  const handleDeleteAllProductClick = () => {
    setOpenConfirmDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenConfirmDeleteModal(false);
  };

  const deleteMyProduct = async (itemId: string) => {
    try {
      await deleteProduct({
        upc: itemId,
        location_id: selectedLocation?.value,
      }, "");
      queryClient.invalidateQueries([queryKeys.products]);
      toast.success("Product deleted successfully");
    } catch (error) {
      toast.error("Failed to delete product");
    }
  };

  const deleteProducts = async () => {
    try {
      await deleteProduct({
        upc: "",
        location_id: selectedLocation?.value,
      }, "all");
      queryClient.invalidateQueries([queryKeys.products]);
      toast.success("Request to delete catalog has been submitted successfully");
    } catch (error) {
      toast.error("Failed to submit delete catalog request");
    }
    setOpenConfirmDeleteModal(false);
  };

  const handleClick = () => {
    // 👇️ open file input box on click of another element
    inputRef.current.click();
  };

  useEffect(() => {
    if (locations?.data) {
      setLocationOptions(
        locations?.data.map((location) => ({
          label: location.name,
          value: location.id,
        }))
      );
      let locationOption;
      if (localStorage.getItem("selectedLocation")) {
        locationOption = JSON.parse(localStorage.getItem("selectedLocation"));
      } else {
        locationOption = {
          label: locations?.data[0]?.name,
          value: locations?.data[0]?.id,
        };
      }
      setSelectedLocation(locationOption);
    }
  }, [locations?.data]);

  const onLocationChange = (location) => {
    setSelectedLocation(location);
    localStorage.setItem("selectedLocation", JSON.stringify(location));
  };

  const fileChangeHandler = (event) => {
    let importType;
    if (withCheckDigit) {
      importType = "product-import";
    } else {
      importType = "product-import-nocheck";
    }

    if (!event.target.files[0]) return;
    const formData = new FormData();
    formData.append("location_id", selectedLocation?.value.toString());
    formData.append("type", importType);
    formData.append("file", event.target.files[0]);
    return uploadFile(formData);
  };

  const handleUploadClick = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const uploadFile = async (formData) => {
    setUploading(true);
    try {
      const res = await axiosInstance.post(
        `${baseURL}/products/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            ...getJWTHeader(user),
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        }
      );
      if (res?.status === 200) {
        toast.success("File Uploaded");
      }
    } catch (err: any) {
      toast.error(err?.response?.data);
    } finally {
      setWithCheckDigit(true);
      handleDialogClose();
      setUploadProgress(0);
      setUploading(false);
    }
  };

  const onPageChange = (value) => {
    setPage(value);
  };


  const columns = [
    {
      field: "upc",
      headerName: "UPC",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      maxWidth: 250,
      flex: 2,
      renderCell: (params) => {
        return (
          <Link href="#" onClick={() => openEditModal(params.id)}>
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "price",
      minWidth: 250,
      padding: 15,
      headerName: "$ Price",
      headerAlign: "right",
      align: "right",
      flex: 1,
      renderCell: (params) => <>{formatter.format(params.value)}</>,
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      getActions: (params) => [
        <Tooltip title="Delete product">
          <GridActionsCellItem
            icon={<DeleteIcon />}
            onClick={() => deleteMyProduct(params.id)}
            label="Delete Product"
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <Container maxWidth={false} disableGutters={false} component="main">
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={8}>
            <SearchBar
              searchFields={searchFields}
              setColSearc={setColSearch}
              setValSearch={setValSearch}
            />
          </Grid>
          <Grid item xs={4}>
            <LocationSelector
              value={selectedLocation}
              onChange={onLocationChange}
              options={locationOptions}
            />
          </Grid>
        </Grid>

        <Stack direction="row" spacing={1} justifyContent="flex-end" mt={5}>
          <Button variant="contained" href={csvFilePath} target="_blank">
            Download Template
          </Button>
          <Button
            variant="contained"
            component="label"
            onClick={handleUploadClick}
          >
            Upload Products
          </Button>
          <Button
            variant="contained"
            component="label"
            onClick={handleDeleteAllProductClick}
            disabled={data && data.length === 0}
          >
            Delete Products
          </Button>
          <Button size="small" variant="contained" onClick={openAddModal}>
            Add
          </Button>
        </Stack>
        {data && (
          <CustomTable
            getRowId={(row: any) => row.upc}
            onPageSizeChange={(value) => {
              setRowsPerPage(value);
            }}
            paginationMode="server"
            pagination
            page={page}
            pageSize={rowsPerPage}
            onPageChange={onPageChange}
            rowCount={totalRows ?? 0}
            rowsPerPageOptions={[ROWS_PER_PAGE]}
            columns={columns}
            rows={data}
            title={"Products"}
          />
        )}

        <AddProduct
          locationId={selectedLocation.value}
          edit={editProduct}
          productId={productId}
          openAddProductModal={openAddProductModal}
          product={product}
          setOpenAddProductModal={setOpenAddProductModal}
        />
        <input
          hidden
          accept="*/*"
          type="file"
          onChange={fileChangeHandler}
          ref={inputRef}
        />
        <Dialog
          open={openConfirmDeleteModal}
          keepMounted
          onClose={handleCloseDeleteModal}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Delete Products?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to delete entire product catalog? This
              operation cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteProducts}>Yes</Button>
            <Button onClick={handleCloseDeleteModal}>No</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open} onClose={() => handleDialogClose()}>
          <DialogTitle>Upload Product File</DialogTitle>
          <DialogContent sx={{ height: "150px", width: "600px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={withCheckDigit}
                  onChange={handleChangeWithDigit}
                />
              }
              label="UPCs have check digit"
            />
            {uploadProgress > 0 && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "100%", mr: 1 }}>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                  />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  >{`${Math.round(uploadProgress)}%`}</Typography>
                </Box>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              disabled={uploading}
              variant="contained"
              component="label"
              onClick={() => handleClick()}
            >
              Choose File
            </Button>
            <Button
              variant="contained"
              component="label"
              onClick={() => handleDialogClose()}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default Products;
