export const queryKeys = {
  dashboard: "admin-dashboard",
  locations: "admin-locations",
  location: "admin-location",
  orders: "admin-orders",
  order: "admin-order",
  products: "admin-products",
  product: "admin-product",
  productGroup: "admin-product-group",
  productGroups: "admin-product-groups",
  user: "admin-user",
  users: "admin-users",
  singleUser: "admin-singleUser",
  payments: "admin-payments",
  productJobs: "admin-product-jobs",
  reportJobs: "admin-report-jobs",
  merchants: "admin-merchants",
};
