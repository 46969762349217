import {
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";
import toast from "react-hot-toast";

interface DeleteGroupParams {
  id: number;
}

export function useDeleteGroupProduct(): UseMutateFunction<
  any,
  unknown,
  DeleteGroupParams,
  unknown
> {
  const { axiosInstance, getJWTHeader } = useAxios();
  const { user } = useUser();
  const queryClient = useQueryClient();

  async function deleteProduct(id: number) {
    if (!id) return null;

    const res = await axiosInstance({
      url: `/product-groups/${id}`,
      method: "DELETE",
      headers: getJWTHeader(user),
    });

    return res;
  }

  const { mutate: mutateDeleteGroupProduct } = useMutation(
    ({ id }: { id: number }) => deleteProduct(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.productGroups]);
        toast.success("Group deleted successfully");
      },
    }
  );

  return mutateDeleteGroupProduct;
}
