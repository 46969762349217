import React, { useEffect, useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SellIcon from "@mui/icons-material/Sell";
import InventoryIcon from "@mui/icons-material/Inventory";
import PaidIcon from "@mui/icons-material/Paid";
import WorkIcon from "@mui/icons-material/Work";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useUser } from "../../views/user/hooks/useUser";

interface NavItem {
  id: number;
  name: string;
  to: string;
  icon: JSX.Element;
  subHeader?: string;
}

export const useNav = (): [NavItem[]] => {
  const [items, setItems] = useState<NavItem[]>([]);
  const { user } = useUser();

  useEffect(() => {
      setItems([
        {
          id: 1,
          name: "Dashboard",
          to: "/dashboard",
          icon: <DashboardIcon />,
        },
        {
          id: 2,
          name: "Locations",
          to: `/locations`,
          icon: <LocationOnIcon />,
        },
        {
          id: 3,
          name: "Users",
          to: "/users",
          icon: <PeopleAltIcon />,
        },
        {
          id: 4,
          name: "Orders",
          to: "/orders",
          icon: <SellIcon />,
        },
        {
          id: 5,
          name: "Products",
          to: "/products",
          icon: <InventoryIcon />,
        },
        {
          id: 6,
          name: "Product Groups",
          to: "/product-groups",
          icon: <InventoryIcon />,
        },
        {
          id: 7,
          name: "Product Jobs",
          to: "/product-jobs",
          icon: <WorkIcon />,
        },
        {
          id: 8,
          name: "Transactions",
          to: "/transactions",
          icon: <PaidIcon />,
        },
        {
          id: 9,
          name: "Reports",
          to: "/reports",
          icon: <PaidIcon />,
        },
      ]);
  }, []);

  return [items];
};
