import { useState } from "react";

import { Box, Container, Grid, Link } from "@mui/material";
import SearchBar from "../../components/SearchBar/SearchBar";
import CustomTable from "../../components/Table/Table";
import { ROWS_PER_PAGE } from "../../helpers";
import { EditLocation } from "./EditLocation";
import { useLocations } from "./hooks/useLocations";

const searchFields = [
  {
    id: 1,
    value: "name",
    label: "Name",
  },
  {
    id: 2,
    value: "contact",
    label: "Contact",
  },
  {
    id: 3,
    value: "email",
    label: "Email",
  },
  {
    id: 4,
    value: "mid",
    label: "MID",
  },
];

const Locations = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [colSearch, setColSearch] = useState("name");
  const [valSearch, setValSearch] = useState("");

  const locations = useLocations({
    p: page + 1,
    lm: rowsPerPage,
    c: colSearch,
    s: valSearch,
  });

  const totalRows = locations?.total_records;

  const data = locations?.data?.length > 0 ? locations.data : locations;

  const openEditModal = (locationId) => {
    setSelectedLocationId(locationId);
    setModalOpen(true);
  };

  const onPageChange = (value) => {
    setPage(value);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Link href="#" onClick={() => openEditModal(params.id)}>
          {params.value}
        </Link>
      ),
    },
    {
      field: "tax_rate",
      headerName: "Tax Rate",
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
    },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
    },
  ];

  return (
    <Container maxWidth={false} disableGutters={false} component="main">
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12}>
            <SearchBar
              searchFields={searchFields}
              setColSearc={setColSearch}
              setValSearch={setValSearch}
            />
          </Grid>
        </Grid>

        {data && (
          <CustomTable
            onPageSizeChange={(value) => {
              setRowsPerPage(value);
            }}
            paginationMode="server"
            pagination
            page={page}
            pageSize={rowsPerPage}
            onPageChange={onPageChange}
            rowCount={totalRows ?? 0}
            rowsPerPageOptions={[ROWS_PER_PAGE]}
            columns={columns}
            rows={data}
            title={"Locations"}
          />
        )}

        <EditLocation
          locationId={selectedLocationId}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      </Box>
    </Container>
  );
};

export default Locations;
