import { Box, Stack, Typography } from "@mui/material";
import Select from "react-select";

type Option = {
  value: number;
  label: string;
};

interface LocationSelectorProps {
  value: Option;
  onChange: (value: Option) => void;
  options: Option[];
}

const LocationSelector = ({
  value,
  onChange,
  options,
}: LocationSelectorProps) => {
  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
      <Typography variant="body1">Location:</Typography>
      <Box sx={{ width: "250px" }}>
        <Select value={value} onChange={onChange} options={options} />
      </Box>
    </Stack>
  );
};

export default LocationSelector;
