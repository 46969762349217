import React from "react";
import { useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import SearchBar from "../../components/SearchBar/SearchBar";
import CustomTable from "../../components/Table/Table";
import { formatter, ROWS_PER_PAGE } from "../../helpers";
import LocationSelector from "../../components/LocationSelector/LocationSelector";
import { usePayments } from "./hooks/usePayments";
import { useLocations } from "../locations/hooks/useLocations";

const searchFields = [
  {
    id: 1,
    value: "order_id",
    label: "Order ID",
  },
  {
    id: 2,
    value: "card_number",
    label: "Card Number",
  },
];

const Payments = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [colSearch, setColSearch] = useState("order_id");
  const [valSearch, setValSearch] = useState("");
  const [locationOptions, setLocationOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: number;
  }>({ label: "", value: 0 });

  const payments = usePayments({
    p: page + 1,
    lm: rowsPerPage,
    c: colSearch,
    s: valSearch,
    l: selectedOption?.value,
  });

  const locations = useLocations({
    p: 1,
    lm: 50,
  });
  const totalRows = payments?.total_records;
  const data = payments?.data?.length > 0 ? payments.data : payments;

  useEffect(() => {
    if (locations?.data) {
      setLocationOptions(
        locations?.data.map((location) => ({
          label: location.name,
          value: location.id,
        }))
      );
      let locationOption;
      if (localStorage.getItem("selectedLocation")) {
        locationOption = JSON.parse(localStorage.getItem("selectedLocation"));
      } else {
        locationOption = {
          label: locations?.data[0]?.name,
          value: locations?.data[0]?.id,
        };
      }
      setSelectedOption(locationOption);
    }
  }, [locations?.data]);

  const onPageChange = (value) => {
    setPage(value);
  };

  const columns = [
    {
      field: "create_date",
      headerName: "Date",
      flex: 2,
      valueFormatter: (params) =>
        params?.value ? new Date(params?.value).toLocaleString() : "-",
    },
    {
      field: "order_id",
      headerName: "Order ID",
      headerAlign: "right",
      align: "right",
      flex: 1,
    },
    {
      field: "card_number",
      minWidth: 160,
      flex: 2,
      headerName: "Card #",
    },
    {
      field: "purchase_amount",
      headerName: "$ Amount",
      headerAlign: "right",
      align: "right",
      flex: 1,
      renderCell: (params) => <>{formatter.format(params.value)}</>,
    },
    {
      field: "auth_amount",
      headerName: "$ Auth",
      headerAlign: "right",
      align: "right",
      flex: 1,
      renderCell: (params) => <>{formatter.format(params.value)}</>,
    },
    {
      field: "process_fee",
      headerName: "$ Proc Fee",
      headerAlign: "right",
      align: "right",
      flex: 1,
      renderCell: (params) => <>{formatter.format(params.value)}</>,
    },
    {
      field: "tx_fee",
      headerName: "$ Tx Fee",
      headerAlign: "right",
      align: "right",
      flex: 1,
      renderCell: (params) => <>{formatter.format(params.value)}</>,
    },
    {
      field: "net_amount",
      headerName: "$ Net",
      headerAlign: "right",
      align: "right",
      flex: 1,
      renderCell: (params) => <>{formatter.format(params.value)}</>,
    },
    {
      field: "auth_code",
      headerName: "Auth Code",
      flex: 1.5,
    },
    {
      field: "message_type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 2,
    },
  ];

  const onLocationChange = (location) => {
    setSelectedOption(location);
    localStorage.setItem("selectedLocation", JSON.stringify(location));
  };

  return (
    <Container maxWidth={false} disableGutters={false} component="main">
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={8}>
            <SearchBar
              searchFields={searchFields}
              setColSearc={setColSearch}
              setValSearch={setValSearch}
            />
          </Grid>
          <Grid item xs={4}>
            <LocationSelector
              value={selectedOption}
              onChange={onLocationChange}
              options={locationOptions}
            />
          </Grid>
        </Grid>

        {data && (
          <CustomTable
            onPageSizeChange={(value) => {
              setRowsPerPage(value);
            }}
            paginationMode="server"
            pagination
            page={page}
            pageSize={rowsPerPage}
            onPageChange={onPageChange}
            rowCount={totalRows ?? 0}
            rowsPerPageOptions={[ROWS_PER_PAGE]}
            columns={columns}
            rows={data}
            title={"Transactions"}
            components={{ Toolbar: GridToolbar }}
          />
        )}
      </Box>
    </Container>
  );
};

export default Payments;
