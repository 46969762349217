import { UseMutateFunction, useMutation, useQueryClient } from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";
import toast from "react-hot-toast";
import { Product } from "types";

interface EditProductParams {
  productData: Product;
}

export function useEditProduct(): UseMutateFunction<
  any,
  unknown,
  EditProductParams,
  unknown
> {
  const { axiosInstance, getJWTHeader } = useAxios();
  const { user } = useUser();
  const queryClient = useQueryClient();

  async function editProduct(productData: Product) {
    if (!productData) return null;

    const res = await axiosInstance({
      url: `/v2/products`,
      method: "POST",
      data: productData,
      headers: getJWTHeader(user),
    });

    return res;
  }

  const { mutate: mutateEditProduct } = useMutation(
    ({ productData }: { productData: Product }) => editProduct(productData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.products]);
        queryClient.invalidateQueries([queryKeys.product]);
        toast.success("Product updated successfully");
      },
    }
  );

  return mutateEditProduct;
}
