export const editLocationStyles = {
  paperStyle: {
    padding: "20px !important",
    maxWidth: 480,
    borderRadius: "12px",
    margin: "100px auto",
  },
  avatarStyle: {
    backgroundColor: "green",
  },
  buttonStyle: {
    margin: "8px 0",
  },
  inputStyle: {
    margin: "8px 0",
  },
};

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
