import React, { useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box, MenuItem, Select } from "@mui/material";
import { useEffect } from "react";
import { debounce } from "@mui/material/utils";
import {
  Search,
  StyledInputBase,
  SEARCH_LENGTH,
  SearchIconWrapper,
} from "./styles";

interface SearchBarProps {
  searchFields: { label: string; value: string; id: number }[];
  setColSearc: (value: string) => void;
  setValSearch: (value: string) => void;
}

const SearchBar = ({
  searchFields,
  setColSearc,
  setValSearch,
}: SearchBarProps) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setColSearc(searchFields[0].value);
  }, [searchFields, setColSearc]);

  const handleSearchColumn = (e) => {
    setColSearc(e.target.value);
  };

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        setValSearch(request.input);
      }, 800),
    []
  );

  useEffect(() => {
    fetch({ input: inputValue }, (results) => {});
  }, [inputValue, fetch]);

  const handleSearchInput = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <Box sx={{ width: "fit-content" }}>
      <Search>
        <Select
          onChange={handleSearchColumn}
          sx={{ width: "150px", height: "40px" }}
          defaultValue={searchFields[0].value}
        >
          {searchFields &&
            searchFields.map((field) => (
              <MenuItem value={field.value} key={field.id}>
                {field.label}
              </MenuItem>
            ))}
        </Select>

        <StyledInputBase
          type="search"
          sx={{ width: SEARCH_LENGTH }}
          value={inputValue}
          onChange={handleSearchInput}
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          autoFocus={true}
        />

        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
      </Search>
    </Box>
  );
};

export default SearchBar;
