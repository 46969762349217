export const forgotStyles = {
  paperStyle: {
    padding: "20px !important",
    maxWidth: 480,
    borderRadius: "12px",
    margin: "100px auto",
  },
  avatarStyle: {
    backgroundColor: "green",
  },
  buttonStyle: {
    margin: "8px 0",
  },
  inputStyle: {
    margin: "8px 0",
  },
};
