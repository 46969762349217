import { useQuery } from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";

const fallback = null;

interface UseOrdersProps {
  p?: number;
  lm?: number;
  c?: string;
  s?: string;
  l?: number;
}

export function useOrders({
  p = 1,
  c = "",
  s = "",
  l = 0,
  lm = 20,
}: UseOrdersProps) {
  if (c === "") c = "id";
  const { user } = useUser();
  const { axiosInstance, getJWTHeader } = useAxios();

  async function getOrders() {
    if (!l) return null;
    const { data } = await axiosInstance({
      url: `/v2/orders?p=${p}&lm=${lm}&c=${c}&s=${s}&l=${l}`,
      method: "GET",
      headers: getJWTHeader(user),
    });

    return data;
  }

  const { data = fallback } = useQuery(
    [queryKeys.orders, p, s, l, lm],
    () => getOrders(),
    {
      staleTime: 0,
      cacheTime: 300000, // 5 minutes
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      enabled: l !== 0
    }
  );

  return data;
}
