import { useQuery } from "@tanstack/react-query";
import { LocationsData } from "types";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";

const fallback = null;

interface UseLocationsProps {
  p?: number;
  c?: string;
  s?: string;
  lm?: number;
}

export function useLocations({
  p = 1,
  c = "",
  s = "",
  lm = 20,
}: UseLocationsProps): LocationsData {
  if (c === "") c = "name";
  const { user } = useUser();
  const { axiosInstance, getJWTHeader } = useAxios();

  async function getLocations() {
    const { data } = await axiosInstance({
      url: `/locations?p=${p}&lm=${lm}&c=${c}&s=${s}`,
      method: "GET",
      headers: getJWTHeader(user),
    });

    return data;
  }

  const { data = fallback } = useQuery(
    [queryKeys.locations, p, s, lm],
    () => getLocations(), {
    // cacheTime: 0,
    // staleTime: 0,
  }
  );

  return data;
}
