import { UseMutateFunction, useMutation, useQueryClient } from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";
import toast from "react-hot-toast";
import { User } from "types";

interface AddUserParams {
  newUser: User;
}

export function useAddUser(): UseMutateFunction<
  any,
  unknown,
  AddUserParams,
  unknown
> {
  const { axiosInstance, getJWTHeader } = useAxios();
  const { user } = useUser();
  const queryClient = useQueryClient();

  async function addUser(newUser) {
    if (!newUser) return null;

    const res = await axiosInstance({
      url: `/users`,
      method: "POST",
      data: newUser,
      headers: getJWTHeader(user),
    });

    return res;
  }

  const { mutate: mutateAddUser } = useMutation(
    ({ newUser }: { newUser: User }) => addUser(newUser),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.users]);
        toast.success("New User added successfully");
      },
    }
  );

  return mutateAddUser;
}
