import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useUser } from "../views/user/hooks/useUser";
import jwt_decode from "jwt-decode";
import useAxios from "../axiosInstance";
import { useLogout } from "./useLogout";
import { User } from "types";
import { CookieSetOptions } from "universal-cookie";

interface UseAuth {
  signin: (
    email: string,
    password: string,
    setLoading: (loading: boolean) => void,
    setCookie: (name: "email", value: any, options?: CookieSetOptions) => void
  ) => Promise<void>;
  signout: () => void;
  logout: () => void;
}

type UserResponse = { user: User };
type ErrorResponse = { message: string };
type AuthResponseType = UserResponse | ErrorResponse;

export function useAuth(): UseAuth {
  const navigate = useNavigate();
  const mutateLogout = useLogout();

  const { axiosInstance } = useAxios();

  const SERVER_ERROR = "There was an error contacting the server.";
  const { clearUser, updateUser } = useUser();

  async function logout() {
    mutateLogout();
  }

  function logInUser(userData: any, setLoading: (loading: boolean) => void) {
    if ("idToken" in userData) {
      const { user_id, email, name } = jwt_decode<any>(
        userData?.idToken as string
      );

      // Create the apropriate user object
      const user = {
        userId: user_id,
        email: email,
        name: name,
        token: userData?.idToken,
        refreshToken: userData?.refreshToken,
        avatar: "",
        location: userData?.location,
        locationId: userData?.locationId,
        roleId: userData?.roleId,
        taxRate: userData?.taxRate,
      };
      setLoading(false);
      updateUser(user);
    }
  }

  async function signin(
    email: string,
    password: string,
    setLoading: (loading: boolean) => void,
    setCookie: (name: "email", value: any, options?: CookieSetOptions) => void
  ) {
    setLoading(true);
    try {
      const res = await axiosInstance({
        url: "/v2/auth/login",
        method: "POST",
        data: { email, password },
        headers: {
          "Content-Type": "application/json",
        },
      });
      setCookie("email", email);

      const userData = {
        location: res?.data?.location,
        idToken: res?.data?.id_token,
        refreshToken: res?.data?.refresh_token,
        expiresIn: res?.data?.expires_in,
        roleId: res?.data?.role_id,
        name: res?.data?.name,
      };

      logInUser(userData, setLoading);
    } catch (errorResponse: any) {
      toast.error(errorResponse?.response?.data);

      setLoading(false);

      if (axios.isAxiosError(errorResponse)) {
        const errorMessages = errorResponse?.response?.data?.errors;

        if (errorMessages?.length === 0) {
          toast.error(SERVER_ERROR);
        } else {
          errorMessages.forEach((error) => {
            toast.error(error);
          });
        }
      }
    } finally {
      setLoading(false);
    }
  }

  function signout() {
    // clear user from stored user data
    clearUser();
    toast.success("Logged out!");
    navigate("/login");
  }

  // Return the user object and auth methods
  return {
    signin,
    signout,
    logout,
  };
}
