import { UseMutateFunction, useMutation, useQueryClient } from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";
import toast from "react-hot-toast";
import { Location } from "types";

interface UpdateLocationParams {
  newLocation: Location;
  locationId: number;
}

export function useUpdateLocation(): UseMutateFunction<
  any,
  unknown,
  UpdateLocationParams,
  unknown
> {
  const { axiosInstance, getJWTHeader } = useAxios();
  const { user } = useUser();
  const queryClient = useQueryClient();

  async function editLocation(newLocation: Location, locationId: number) {
    if (!newLocation) return null;
    if (!locationId) return null;

    const res = await axiosInstance.put(
      `/locations/${locationId}`,
      newLocation,
      {
        headers: getJWTHeader(user),
      }
    );

    return res;
  }

  const { mutate: mutateLocation } = useMutation(
    ({
      newLocation,
      locationId,
    }: {
      newLocation: Location;
      locationId: number;
    }) => editLocation(newLocation, locationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.locations]);
        queryClient.invalidateQueries([queryKeys.location]);
        toast.success("Location updated successfully");
      },
    }
  );

  return mutateLocation;
}
