import axios from "axios";
import {useQueryClient} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {queryKeys} from "../react-query/constants";
import {setStoredUser} from "../user-storage";
import {baseURL} from "./constants";

const config = { baseURL: baseURL };
const axiosInstance = axios.create(config);

const useAxios = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  function getJWTHeader(user) {
    return { Authorization: `Bearer ${user?.token}` };
  }

  const refreshAccessToken = async () => {
    const user: any = queryClient.getQueryData([queryKeys.user]);
    console.log(user)
    const refreshToken = user?.refreshToken || "";
    const headers: any = {
      "Content-Type": "application/form-x-encoded",
      "Access-Control-Allow-Origin": "*",
    };
    const data: any = {};
    if (refreshToken === "" || !refreshToken) return;

    data.refreshToken = refreshToken;

    const res = await axiosInstance.post(
      `/auth/refresh-token`,
      // `/auth/refresh-token?key=${process.env.REACT_APP_API_KEY}`,
      data,
      headers
    );

    // Sets new token in the cached user
    const updatedUser = {
      ...user,
      token: res?.data?.idToken,
      refreshToken: res?.data?.refreshToken,
    };
    queryClient.setQueryData([queryKeys.user], updatedUser);
    setStoredUser(updatedUser);
    return res;
  };

  // Add a request interceptor to add the x-api-key header
  axiosInstance.interceptors.request.use((config) => {
    config.headers["x-api-key"] = process.env.REACT_APP_API_KEY
    return config
  })

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error?.response?.status === 503) {
        navigate("/maintenance");
      }
      if (
        error.response?.status === 401 &&
        (error.response?.data.toLowerCase().includes("jwt is expired") ||
          error?.response?.data
            .toLowerCase()
            .includes("unexpected end of json")) &&
        !originalRequest._retry
      ) {
        try {
          let res = await refreshAccessToken();
          if (res?.status === 200 || res?.status === 201) {
            localStorage.setItem("idToken", res.data.idToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);

            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${res.data.idToken}`;
            return axios(originalRequest);
          } else {
            localStorage.clear();

            return Promise.reject(error);
          }
        } catch (error) {
          return Promise.reject(error);
        }
      } else if (error.response?.status === 401) {
        localStorage.clear();
      } else {
        throw error;
      }
      return Promise.reject(error);
    }
  );

  return { getJWTHeader, axiosInstance };
};

export default useAxios;
