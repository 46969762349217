import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useUser } from "../views/user/hooks/useUser";
import useAxios from "../axiosInstance";

export function useLogout() {
  const { axiosInstance, getJWTHeader } = useAxios();
  const { user, clearUser } = useUser();
  const navigate = useNavigate();

  async function logout() {
    const res = await axiosInstance({
      url: `/auth/logout`,
      method: "POST",
      params: { key: process.env.REACT_APP_API_KEY },
      headers: getJWTHeader(user),
    });
    localStorage.removeItem("user");
    localStorage.removeItem("idToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("selectedMerchant");

    return res;
  }

  const { mutate: mutateAddUser } = useMutation(() => logout(), {
    onSuccess: () => {
      clearUser();
      toast.success("Logged out!");
      navigate("/login");
    },
  });

  return mutateAddUser;
}
