import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ isAuthenticated, authenticationPath, outlet }) => {
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  const location = useLocation();
  if (isAuthenticated) {
    return outlet;
  } else {
    return (
      <Navigate to={authenticationPath} replace state={{ from: location }} />
    );
  }
};

export default PrivateRoute;
