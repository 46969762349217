import { UseMutateFunction, useMutation, useQueryClient } from "@tanstack/react-query";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";
import toast from "react-hot-toast";
import { User } from "types";

interface EditUserParams {
  userId: number;
  userData: User;
}

export function useEditUser(): UseMutateFunction<
  any,
  unknown,
  EditUserParams,
  unknown
> {
  const { axiosInstance, getJWTHeader } = useAxios();
  const { user } = useUser();
  const queryClient = useQueryClient();

  async function editUser(userId, userData) {
    if (!userData) return null;
    if (!userId) return null;

    const res = await axiosInstance({
      url: `/users/${userId}`,
      method: "PUT",
      data: userData,
      headers: getJWTHeader(user),
    });

    return res;
  }

  const { mutate: mutateEditUser } = useMutation(
    ({ userId, userData }: { userId: number; userData: User }) =>
      editUser(userId, userData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.users]);
        toast.success("User updated successfully");
      },
    }
  );

  return mutateEditUser;
}
