import {
  Box,
  Card,
  CardContent,
  Divider,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { convertTextToDate, formatter } from "../../helpers";
import { useOrder } from "./hooks/useOrder";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface PaymentDetailProps {
  orderId: number;
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
}

const PaymentDetail = ({
  orderId = null,
  modalOpen = false,
  setModalOpen,
}: PaymentDetailProps) => {
  const order = useOrder(orderId);

  const onClose = () => {
    setModalOpen(false);
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Order Details
            </Typography>

            {order?.voided === true && (
              <Typography
                variant="body1"
                color={(theme) => theme.palette.error.main}
                gutterBottom
              >
                VOIDED
              </Typography>
            )}
          </Box>
          <Divider />
          <Typography
            variant="body1"
            color="text.secondary"
            gutterBottom
            sx={{ display: "inline-block", fontWeight: "bold" }}
          >
            Sale Date:{" "}
          </Typography>
          <Typography variant="body1" sx={{ display: "inline-block" }} ml={1}>
            {order?.sale_date && convertTextToDate(order?.sale_date)}
          </Typography>
          <br />
          <Typography
            variant="body1"
            color="text.secondary"
            gutterBottom
            sx={{ display: "inline-block", fontWeight: "bold" }}
          >
            Order ID:
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            gutterBottom
            sx={{ display: "inline-block" }}
            ml={1}
          >
            {order?.id}
          </Typography>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">Qty</TableCell>
                  <TableCell align="right">UPC</TableCell>
                  <TableCell align="right">Description</TableCell>
                  <TableCell align="right">Refunded</TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order?.items &&
                  order?.items.map((row) => (
                    <TableRow
                      key={row.upc}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="right">{row.quantity}</TableCell>
                      <TableCell align="right">{row.upc}</TableCell>
                      <TableCell align="right">{row.name}</TableCell>
                      <TableCell align="right">
                        {row.refunded ? "Yes" : "No"}
                      </TableCell>
                      <TableCell align="right">
                        {formatter.format(row.price)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            mt={4}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
            >
              { `Card: ${order?.card}`}
              <br />
            </Typography>

            <Stack spacing={2} justifyContent="flex-end" alignItems="flex-end">
              <Box>
                <Typography
                  variant="body1"
                  sx={{ display: "inline-block", fontWeight: "bold" }}
                >
                  Subtotal:
                </Typography>

                <Typography
                  variant="body1"
                  sx={{ display: "inline-block" }}
                  ml={1}
                >
                  {formatter.format(
                    order?.total - order?.tax
                  )}
                </Typography>
              </Box>

              <Box>
                <Typography
                  variant="body1"
                  sx={{ display: "inline-block", fontWeight: "bold" }}
                >
                  Tax:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ display: "inline-block" }}
                  ml={1}
                >
                  {formatter.format(order?.tax)}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ display: "inline-block", fontWeight: "bold" }}
                >
                  Total:
                  <br />
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ display: "inline-block" }}
                  ml={1}
                >
                  {formatter.format(order?.total)}
                  <br />
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default PaymentDetail;
