import { useQuery } from "@tanstack/react-query";
import { DashboardData } from "types";
import useAxios from "../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { useUser } from "../../user/hooks/useUser";

const fallback = null;

export function useDashboard(): DashboardData {
  const { user } = useUser();
  const { axiosInstance, getJWTHeader } = useAxios();

  async function getDashboard() {
    const { data } = await axiosInstance({
      url: `/dashboard`,
      method: "GET",
      headers: getJWTHeader(user),
    });

    return data;
  }

  const { data = fallback } = useQuery(
    [queryKeys.dashboard],
    () => {
      return getDashboard();
    }
  );

  return data;
}
