import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import ForgotPassword from "../forgot-password/ForgotPassword";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import metadata from "../../../metadata.json";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import background from "../../../assets/logo.png";
import { loginStyles } from "./styles";
import { useAuth } from "../../../auth/useAuth";
import { useUser } from "../hooks/useUser";

const Login = () => {
  const auth = useAuth();
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["email"]);
  const [modal, setModal] = useState(false);

  type FormValues = {
    email: string;
    password: string;
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required!"),
    password: Yup.string().required(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "all",
    defaultValues: {
      email: cookies.email || "",
      password: "",
    },
  });

  const onSubmit = (values: FormValues) => {
    auth.signin(values.email, values.password, setLoading, setCookie);
  };

  useEffect(() => {
    if (user) {
      const from = location.state?.from || "/dashboard";
      navigate(from, {});
    }
  }, [user]);

  const forgotPasswordAction = () => {
    setModal(!modal);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <Container maxWidth={false} component="main" disableGutters={true}>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          height: "100vh",
        }}
      >
        <Container>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              alt=""
              src={background}
              style={{ paddingTop: "6rem", paddingBottom: "6rem" }}
            />
          </Box>
          <Paper elevation={10} sx={{ ...loginStyles.paperStyle }}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              name="LoginForm"
              className="loginForm"
            >
              <Typography variant="h1" component="h1" align="center">
                Login
              </Typography>
              <TextField
                error={errors.email ? true : false}
                helperText={errors?.email?.message}
                type="email"
                id="email"
                name="email"
                label="Email"
                placeholder="Enter email"
                fullWidth
                variant="filled"
                {...register("email")}
                sx={loginStyles.inputStyle}
              />
              <TextField
                id="password"
                error={errors.password ? true : false}
                helperText={errors?.password?.message}
                label="Password"
                placeholder="Enter password"
                type={showPassword ? "text" : "password"}
                fullWidth
                variant="filled"
                {...register("password")}
                sx={loginStyles.inputStyle}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Link
                  component="button"
                  type="button"
                  underline="hover"
                  onClick={() => forgotPasswordAction()}
                >
                  <Typography color="primary.light">
                    Forgot password ?
                  </Typography>
                </Link>
              </Box>
              <LoadingButton
                id="submit"
                loading={loading}
                type="submit"
                sx={loginStyles.buttonStyle}
                variant="contained"
                fullWidth
                color="primary"
              >
                Log In
              </LoadingButton>
            </form>
          </Paper>
        </Container>
        <Box style={{ color: "white" }} textAlign="center">
          Version {metadata.buildMajor}.{metadata.buildMinor}.
          {metadata.buildRevision}
        </Box>
      </Paper>

      <ForgotPassword modal={modal} toggle={forgotPasswordAction} />
    </Container>
  );
};

export default Login;
