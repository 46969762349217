import DeleteIcon from "@mui/icons-material/Delete";
import LockResetIcon from "@mui/icons-material/LockReset";
import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar/SearchBar";
import CustomTable from "../../components/Table/Table";
import EditUser from "./EditUser";

import StarIcon from "@mui/icons-material/Star";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { GridActionsCellItem } from "@mui/x-data-grid";
import toast from "react-hot-toast";
import useAxios from "../../axiosInstance";
import LocationSelector from "../../components/LocationSelector/LocationSelector";
import { ROWS_PER_PAGE } from "../../helpers";
import { useLocations } from "../locations/hooks/useLocations";
import { useUser } from "../user/hooks/useUser";
import AddUser from "./AddUser";
import { useUsers } from "./hooks/useUsers";
import { useDeleteUser } from "./hooks/useDeleteUser";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const searchFields = [
  {
    id: 1,
    value: "name",
    label: "Name",
  },
  {
    id: 2,
    value: "email",
    label: "Email",
  },
];

const Users = () => {
  const [page, setPage] = useState(0);
  const [userId, setUserId] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [colSearch, setColSearch] = useState("");
  const [valSearch, setValSearch] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const { axiosInstance, getJWTHeader } = useAxios();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(0);

  const mutateDeleteUser = useDeleteUser();

  const handleClose = () => {
    setOpenConfirmDelete(false);
  };

  const { user } = useUser();

  const users = useUsers({
    p: page + 1,
    lm: rowsPerPage,
    c: colSearch,
    s: valSearch,
    l: selectedOption?.value,
  });

  const totalRows = users?.total_records;
  const locations = useLocations({
    p: 1,
    lm: 50,
  });

  const data = users?.data?.length > 0 ? users.data : users;

  useEffect(() => {
    if (locations?.data) {
      setLocationOptions(
        locations?.data.map((location) => ({
          label: location.name,
          value: location.id,
        }))
      );
      let locationOption;
      if (localStorage.getItem("selectedLocation")) {
        locationOption = JSON.parse(localStorage.getItem("selectedLocation"));
      } else {
        locationOption = {
          label: locations?.data[0]?.name,
          value: locations?.data[0]?.id,
        };
      }
      setSelectedOption(locationOption);
    }
  }, [locations?.data]);

  const openAddModal = () => {
    setAddModalOpen(true);
  };

  const openEditModal = (userId) => {
    setUserId(userId);
    setEditModalOpen(true);
  };
  const deleteUser = async () => {
    mutateDeleteUser({ userId: userIdToDelete });
    handleClose();
  };

  const handleDeleteUser = (userId) => {
    setUserIdToDelete(userId);
    setOpenConfirmDelete(true);
  };

  const resetPasswordAPI = async (email) => {
    let header = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    const idToken = localStorage.getItem("idToken") || "";

    if (idToken !== "") {
      header["Authorization"] = `Bearer ${idToken}`;
    }
    const response = await axiosInstance({
      url: `/auth/forgot-password`,
      method: "POST",
      data: {"email": email},
      headers: getJWTHeader(user),
    });
    if (response?.status === 200) {
      toast.success("Reset Password Email is sent");
    } else {
      toast.error("Something went wrong. Please try again");
    }
  };

  const onLocationChange = (location) => {
    setSelectedOption(location);
    localStorage.setItem("selectedLocation", JSON.stringify(location));
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <Link href="#" onClick={() => openEditModal(params.id)}>
            {params.row.main === true ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Main User">
                  <IconButton color="inherit">
                    <StarIcon />
                  </IconButton>
                </Tooltip>
                {params.value}
              </Box>
            ) : (
              params.value
            )}
          </Link>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
    },
    {
      field: "role_id",
      headerName: "Role",
      flex: 1,
      // renderCell: (params) => findRole(params.value),
      renderCell: (params) => {
        return params.row.role_name;
      },
    },
    {
      field: "enabled",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <>
          <Chip
            label={params.value === true ? "enabled" : "disabled"}
            color={params.value === true ? "success" : "error"}
          />
        </>
      ),
    },
    {
      field: "last_login",
      headerName: "Last Login",
      flex: 2,
      valueFormatter: (params) =>
        params?.value ? new Date(params?.value).toLocaleString() : "-",
    },
    {
      field: "create_date",
      headerName: "Date Created",
      flex: 2,
      valueFormatter: (params) =>
        params?.value ? new Date(params?.value).toLocaleString() : "-",
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.5,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<LockResetIcon />}
          onClick={() => resetPasswordAPI(params.row.email)}
          label="Reset Password"
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => handleDeleteUser(params.id)}
          label="Delete User"
          showInMenu
        />,
      ],
    },
  ];

  const onPageChange = (value) => {
    setPage(value);
  };

  return (
    <Container maxWidth={false} disableGutters={false} component="main">
      <Dialog
        open={openConfirmDelete}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete User?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete? This operation cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteUser}>Yes</Button>
          <Button onClick={handleClose}>No</Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          sx={{ alignItems: "baseline" }}
        >
          <Grid item xs={8}>
            <SearchBar
              searchFields={searchFields}
              setColSearc={setColSearch}
              setValSearch={setValSearch}
            />
          </Grid>
          <Grid item xs={4}>
            <LocationSelector
              value={selectedOption}
              onChange={onLocationChange}
              options={locationOptions}
            />
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "end" }}>
            <Button variant="contained" onClick={() => openAddModal()}>
              Add
            </Button>
          </Grid>
        </Grid>

        {data && (
          <CustomTable
            onPageSizeChange={(value) => {
              setRowsPerPage(value);
            }}
            paginationMode="server"
            pagination
            page={page}
            pageSize={rowsPerPage}
            onPageChange={onPageChange}
            rowCount={totalRows ?? 0}
            rowsPerPageOptions={[ROWS_PER_PAGE]}
            columns={columns}
            rows={data}
            title={"Users"}
          />
        )}
        <EditUser
          userId={userId}
          editModalOpen={editModalOpen}
          setEditModalOpen={setEditModalOpen}
        />
        <AddUser
          addModalOpen={addModalOpen}
          setAddModalOpen={setAddModalOpen}
        />
      </Box>
    </Container>
  );
};

export default Users;
